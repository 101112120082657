import "../styles/caseprojet.css";
import { Dimensions } from "react-native";

function CaseProjet({
  cover,
  name,
  name2,
  description,
  technos,
  id,
  updateActive,
  active,
  lien,
}) {
  const windowHeight = Dimensions.get("window").width;
  
  function afficherTechnos(tablo) {
    var res = "";
    for (let i = 0; i < tablo.length; i++) {
      var element = tablo[i];
      res += element + ", ";
    }
    res = res.substring(0, res.length - 2);
    return (
      <div className="technos">
        <div className="desctechnos">
          Technologie(s) / Equipement(s) utilisé(s){" "}
        </div>
        <br className="br" /> {res}
      </div>
    );
  }

  return (
    <div className="un-projet">
      <center>
        <div
          id={id}
          className={
            id === active ? "titre noselect jej selec" : "titre noselect jej "
          }
          onClick={() => (active === id ? updateActive(0) : updateActive(id))}
        >
          {name}
        </div>
      </center>
      <div className={id === active ? "grosbloc" : "hidden"}>
      <a href={lien}>
        <img className="image" src={cover} alt={`${name} cover`} />
        </a>
      </div>
      {windowHeight > 744 ? (
        <div
          className={
            (id % 2 === 0 && parseInt(active) === parseInt(id) - 1) ||
            (id % 2 === 1 && parseInt(active) === parseInt(id) + 1)
              ? "blocdroite"
              : "hidden"
          }
        >
          <div className="soustitre">{name2}</div>
          <div>{description}</div>
          <div>{afficherTechnos(technos)}</div>
        </div>
      ) : (
        <div className={id === active ? "blocbas" : "hidden"}>
          <div className="soustitre">{name2}</div>
          <div>{description}</div>
          <div>{afficherTechnos(technos)}</div>
        </div>
      )}
    </div>
  );
}

export default CaseProjet;
