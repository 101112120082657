import CV from "../assets/CV.jpg";
import "../styles/cv.css";
import { useState } from "react";

function AfficherCv() {
  var [cache, update] = useState(true);

  function ntm(jej) {
    //   console.log(jej);
    return jej ? update(false) : update(true);
  }
  return (
    <center>
      <div className="conteneur">
        <img
          className={cache ? "cv cache" : "cv montre"}
          src={CV}
          alt="CV"
          onClick={() => ntm(cache)}
        />
        <div className={cache ? "cv cv2" : "hidden"} onClick={() => ntm(cache)}>
          cliquez pour afficher le cv en entier
        </div>
      </div>
    </center>
  );
}

export default AfficherCv;
