import jej from "../assets/jej.jpeg";
import verre from '../assets/verre.jpeg';
import S3 from '../assets/S3.jpeg'
import etienne from '../assets/etienne.jpeg'
import covergaming from '../assets/covergaming.jpeg'
import fan2pirates from '../assets/FAN2PIRATES.png'
import miniaquoridor from '../assets/miniaquoridor.png'
import miniasite from '../assets/miniapoursite.jpg'
import miniafan2arene from '../assets/miniafan2arene.png'


export const PList = [
  {
    name: "jeu navigateur",
    name2: "fan2pirates",
    category: "programmation",
    id: "1",
    cover: fan2pirates,
    description:
      "Réalisation d'un jeu dans un groupe de 4 étudiants. Ce projet est entièrrement codé en JavaScript avec la librairie Phaser 3. Cliquez pour voir le projet ! (ouvrir avec Chrome ou Brave)",
    technos: ["JavaScript", "Phaser3"],
    lien: "https://adrien.comas.fr/FAN2PIRATES",
  },
  {
    name: "IA quoridor",
    name2: "Intelligence Artificielle Quoridor",
    category: "programmation",
    id: "2",
    cover: miniaquoridor,
    description: "Création d'intelligences artificielles jouant au jeu du Quoridior en groupe de 4 étudiants. L'interface a également été imaginée, créée et implémentée par nos soins. Cliquez sur l'image pour acceder au site.",
    technos: ["Python", "C++","NodeJS","JavaScript","Phaser3"],
    lien:"https://baubertin.com/quoridor",
  },

  {
    name: "ce site",
    name2: "Portfolio 2022",
    category: "programmation",
    id: "3",
    cover: miniasite,
    description: "Ce site a été réalisé avec la bibliothèque React.js. Toutes les assets ont été réalisées par mes soins.",
    technos: ["React", "JavaScript", "CSS"],
    lien: "",
  },
  {
    name: "musique",
    name2: "Mixtape Happy Synth vol.1",
    category: "audiovisuel",
    id: "4",
    cover: covergaming,
    description: "Mixtape synthwave sortie en juin 2020 disponible sur toutes les plateformes de streaming. Cliquez sur l'image pour écouter.",
    technos: ["Cubase"],
    lien: "https://distrokid.com/hyperfollow/chillybilly/happy-synth-vol-1",
  },

  {
    name: "jeu mobile",
    name2: "fan2arène",
    category: "programmation",
    id: "5",
    cover: miniafan2arene,
    description: "Création d'un jeu mobile sur Unity comme projet de fin d'études d'IUT. Cliquez sur l'image pour acceder au compte rendu du projet!",
    technos: ["Unity"],
    lien: "https://adrien.comas.fr/Adrien_Comas_PFE.pdf",
  },

  {
    name: "Communication vidéo",
    name2: "Communication Etienne café",
    category: "audiovisuel",
    id: "6",
    cover: etienne,
    description: "Création d'une vidéo promotionnelle pour Etienne café Narbonne dans le cadre du marathon MMI 2019. Cliquez sur l'image pour visionner !",
    technos: ["Adobe Premiere Pro", "Canon 4D mkIV"],
    lien: "https://www.youtube.com/watch?v=U0zvl6NbsRQ",
  },

  {
    name: "Communication vidéo",
    name2: "Communication entreprise recyclage",
    category: "audiovisuel",
    id: "7",
    cover: verre,
    description: "Création d'une vidéo promotionnelle pour une entreprise de recyclage dans le cadre du marathon MMI 2020. Cliquez sur l'image pour visionner !",
    technos: ["Adobe Premiere Pro", "Canon 4D mkIV"],
    lien: "https://www.youtube.com/watch?v=xJ_qAh2fBfU",
  },

  {
    name: "tournage",
    name2: "Court métrage Fan N°1",
    category: "audiovisuel",
    id: "8",
    cover: S3,
    description: "Court métrage réalisé dans le cadre d'un cours d'audiovisuel. Cliquez sur l'image pour visionner !",
    technos: ["Adobe Premiere Pro", "Canon 5D mkIV", "Cubase"],
    lien: "",
  },
];
