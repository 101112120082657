import "../styles/index.css";
import ListeProjet from "./ListeProjets";
import titreporfolio from "../assets/titreportfolio.png";
import titrecontact from "../assets/titrecontact.png";
import titrecv from "../assets/titrecv.png";
import hero from "../assets/herotest.png";
import ContactForm from "./ContactForm";
import AfficherCv from "./AfficherCv";
import Footer from "./Footer.js";

function App() {
  return (
    <div>
      <img className="hero" src={hero} alt="hero site" />
      <img
        className="big-titre"
        src={titreporfolio}
        alt="sous-titre portfolio"
      />

      <ListeProjet />
      <img className="big-titre" src={titrecontact} alt="sous-titre contact" />
      <ContactForm />
      <img className="big-titre" src={titrecv} alt="sous-titre cv" />
      <AfficherCv />
      <br className="noselect" />
      <br className="noselect" />
      <br className="noselect" />
      <br className="noselect" />
      <br className="noselect" />
      <br className="noselect" />
      <br className="noselect" />
      <br className="noselect" />
      <br className="noselect" />
      <Footer />
    </div>
  );
}

export default App;
