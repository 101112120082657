import { PList } from "../datas/Projets";
import CaseProjet from "./CaseProjet";
import "../styles/projetsliste.css";
import { useState } from "react";
import React from "react";
import { Dimensions } from "react-native";

function ListeProjet() {
  console.log("DEBUT DE LA FONCTION");
  const [active, updateActive] = useState(0);
  const [activeCategory, majactiveCategory] = useState("a");
  const windowHeight = Dimensions.get("window").width;
  const [sertarien, miseajour] = useState(PList);
  var listeselectionnee = [];
  console.log("sertarien : " + sertarien);
  console.log(activeCategory);
  //Flisteselectionnee();

  function Flisteselectionnee(jej) {
    listeselectionnee = [];
    console.log("jej : " + jej + " activeCate : " + activeCategory);
    activeCategory === jej
      ? (listeselectionnee = PList)
      : PList.map((value, index) =>
          PList[index].category === jej
            ? listeselectionnee.push(PList[index])
            : null
        );
    console.log(listeselectionnee);
    miseajour(listeselectionnee);
  }

  function changecate(jej) {
    console.log("active categorie : " + activeCategory + " jej : " + jej);
    activeCategory === jej ? majactiveCategory("a") : majactiveCategory(jej);
    Flisteselectionnee(jej);
  }

  function cate() {
    return (
      <div>
        <center>
          <ul className="cate">
            {categories.map((value, index) => {
              return (
                <div
                  key={index}
                  onClick={() => changecate(categories[index])}
                  className={
                    categories[index] === activeCategory ? "cateselec" : "cate"
                  }
                >
                  {categories[index]}
                </div>
              );
            })}
          </ul>
        </center>
      </div>
    );
  }

  const categories = PList.reduce(
    (acc, value) =>
      acc.includes(value.category) ? acc : acc.concat(value.category),
    []
  );
  // console.log(active)
  return (
    <div>
      {cate()}

      <ul className="afficherprojets">
        {sertarien.map(
          (
            { id, name2, cover, name, description, technos, category, lien },
            index
          ) =>
            windowHeight > 744 ? (
              index % 2 === 0 ? ( //gauche
                <div key={index + 1}>
                  <CaseProjet
                    cover={cover}
                    name={name}
                    name2={sertarien[index + 1].name2}
                    description={sertarien[index + 1].description}
                    technos={sertarien[index + 1].technos}
                    id={index + 1}
                    active={active}
                    updateActive={updateActive}
                    lien={lien}
                  />
                </div>
              ) : (
                //gauche
                <div key={index + 1}>
                  <CaseProjet
                    cover={cover}
                    name={name}
                    name2={sertarien[index - 1].name2}
                    description={sertarien[index - 1].description}
                    technos={sertarien[index - 1].technos}
                    id={index + 1}
                    active={active}
                    updateActive={updateActive}
                    lien={lien}
                  />
                </div>
              )
            ) : (
              //quune colonne
              <div key={index + 1}>
                <CaseProjet
                  cover={cover}
                  name={name}
                  name2={name2}
                  description={description}
                  technos={technos}
                  id={index + 1}
                  active={active}
                  updateActive={updateActive}
                  lien={lien}
                />
              </div>
            )
        )}
      </ul>
    </div>
  );
}

export default ListeProjet;
