import "../styles/footer.css";
import '../styles/index.css'

function Footer() {
  return (
    <div>
      <center>
        adrien.comas.fr
        <br className="noselect"/>
        2022
        <br className="noselect"/>
        <br className="noselect"/>
      </center>
    </div>
  );
}

export default Footer;
