import React, { useState } from "react";
import "../styles/contactform.css";

const FORM_ENDPOINT =
  "https://public.herotofu.com/v1/70d6c820-97bd-11ec-bdf8-dd9c99f898ec"; // TODO - fill on the later step

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = () => {
    setTimeout(() => {
      setSubmitted(true);
    }, 100);
  };

  if (submitted) {
    return (
      <>
        <h2>Thank you!</h2>
        <div>We'll be in touch soon.</div>
      </>
    );
  }

  return (
    <div>
      <center>
      <form
        action={FORM_ENDPOINT}
        onSubmit={handleSubmit}
        method="POST"
        target="_blank"
      >
        <div className="top">
          <div className="casetop">
            <input
              className="case"
              type="text"
              placeholder="Nom"
              name="nom"
              required
            />
          </div>
          <div className="casetop">
            <input
              className="case"
              type="text"
              placeholder="Prenom"
              name="prenom"
              required
            />
          </div>
          <div className="casetop">
            <input
              className="case"
              type="email"
              placeholder="Email"
              name="email"
              required
            />
          </div>
        </div>
        <div className="bot">
          <input
            className="case"
            type="text"
            placeholder="Sujet"
            name="sujet"
            required
          />
        </div>
        <div className="bot">
          <textarea
            className="case"
            placeholder="Message"
            name="message"
            required
          />
        </div>
        <div className="bouton">
          <button type="submit"> Envoyer </button>
        </div>
      </form>
      </center>
    </div>
  );
};

export default ContactForm;
